import { useState, useEffect } from "react";
import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './pages/index';
import About from './pages/about';

import Navbar from "./components/Navbar/Navbar";

function App() {

  const conversionFactor = 866; // Power Profiler US Average CO@e lbs/MWh

  useEffect(() => {

    // Hide and show kwh or mpg depeneding on type of car fuel
    let mpg = document.getElementById('tblRow_vehicle-miles-per-gallon');
    let kwhm = document.getElementById('tblRow_vehicle-miles-per-kwh');
    let transEnergyRow = document.getElementById('transportationenergy-data-row');
    if(directVars["vehicle-fuel-type"] === 'electric' && (mpg && kwhm && transEnergyRow)){
      mpg.style.display = 'none';
      kwhm.style.display = 'table-row';
      transEnergyRow.style.display = 'table-row';
    }
    else if(mpg && kwhm && transEnergyRow){
      mpg.style.display = 'table-row';
      kwhm.style.display = 'none';

      transEnergyRow.style.display = 'none';
    }

    // If natural gas heating, show therms
    let heating_units = document.getElementById('space-heating-and-natural-gasenergy-result-units');
      
    if(directVars.primary_fuel_heating === 53){
      heating_units.innerText = "THERMS"
    }
    else{
      heating_units.innerText = "MMBTU"
    }
    
  })
  //THESE STATE VARIABLES MUST MATCH THE IDS OF THE FIELDS IN THE DirectTableData.JSON FILE
  // Set the default values for the calculator
  const [directVars, setDirectVars] = useState({
    "number_of_people" : 1,
    "house_size" : 700,
    "region": 61,
    "house_type": -50,
    "primary_fuel_heating": 53,
    "percent_renewable_heating" : 0,
    "efficiency" : 0,
    "thermostat" : 0,
    "energy_conserve" : 0,
    "hot_tub" : 0,
    "renewable_energy" : 0,
    "vehicle-miles-per-year" : 12000,
    "vehicle-fuel-type" : 'gas',
    "vehicle-miles-per-kwh" : 3.5,
    "vehicle-miles-per-gallon" : 21,
    "alone-in-car" : 100,
    "bus-mpy" : 0,
    "train-mpy" : 0,
    "airplane-mpy" : 0
  });

  const [indirectVars, setIndirectvars] = useState({
    "diet-type" : 2.5,
    "personal-consumption" : 0,
    "personal-service" : 0
  })

  const heatingEnergyFunction = () => {
    var energy;

    const mmbtu_energy = (
      ( 34.4 * ( 1 + directVars.house_type / 100 ) ) / 1000 *
      ( directVars.house_size / directVars.number_of_people ) * 
      ( 1 + directVars.region / 100 ) *
      ( 1 + directVars.efficiency / 100 ) * 
      ( 1 + directVars.thermostat / 100 ) * 
      ( 1 + ( directVars.house_size * 0.0446 - 92.76 ) / 100 )
    )

    energy = mmbtu_energy

    // If natural gas selected, convert to therms
    if(directVars.primary_fuel_heating === 53){
      energy = mmbtu_energy * 10
    }

    return energy;
  }

  const heatingEmissionFunction = () => {

    let energy = heatingEnergyFunction();

    // If natural gas selected, convert to therms
    if(directVars.primary_fuel_heating === 53){
      energy = energy / 10
    }
    var emissions = energy * directVars.primary_fuel_heating / 1000;

  
    return emissions;
  }

  const electricEnergyFunction = () => {

    const energy = 
      10800 
      * ( 1 + ( directVars.house_size * 0.0446 - 92.76 ) / 100 ) 
      * ( 1 + directVars.energy_conserve / 100 ) 
      * ( 1 + directVars.hot_tub / 100 ) 
      * ( 1 + (directVars.efficiency / 100 ) * .5 ) 
      / directVars.number_of_people

    return energy;

  }
  
  const electricEmissionFunction = () => {

    const energy = electricEnergyFunction();

    const mtco2 = ( conversionFactor / 1000 ) / 2200; // converted to metric tons/kwh

    const emissions = energy * ( 1 - directVars.renewable_energy / 100 ) * mtco2;
    
    return emissions;

  }

  const transportationEnergyFunction = () => {

    // kWh per Year
    return directVars["vehicle-miles-per-year"] / directVars["vehicle-miles-per-kwh"] 

  }
  const transportationEmissionFunction = () => {

    var autoTotal;

    if( directVars["vehicle-fuel-type"] !== 'electric' ) // calculate emissions from gas
      autoTotal = ( (directVars["vehicle-miles-per-year"] / directVars["vehicle-miles-per-gallon"] ) * 
        8.91/1000 * directVars["alone-in-car"] / 100 );

    else { // calculate emissions from energy
      let energy = transportationEnergyFunction();

      let mtco2 = ( conversionFactor / 1000 ) / 2200; // converted to metric tons/kwh

      autoTotal = energy * mtco2 * directVars["alone-in-car"] / 100;
    }

    const busTotal = directVars["bus-mpy"] * .17 / 1000;

    const trainTotal = directVars["train-mpy"] * 0.08 / 1000;

    const airplaneTotal = directVars["airplane-mpy"] * 0.018 / 1000;

    const emissions = autoTotal + busTotal + trainTotal + airplaneTotal;

    return emissions;
  }

  const dietFunction = () => {
    return indirectVars["diet-type"] * 1;
  }

  const consumptionFunction = () => {
    return indirectVars["personal-consumption"]/100 + 3;
  }

  const servicesFunction = () => {
    return indirectVars["personal-service"]/100 + 3;
  }

  const totalEmissions = 
        heatingEmissionFunction() + 
        electricEmissionFunction() + 
        transportationEmissionFunction() +
        dietFunction() +
        consumptionFunction() +
        servicesFunction();


  return ( 
    <Router>
      <Navbar />
      <Routes>
          <Route exact path="/" element={
            <Home 
              directVars={directVars} 
              setDirectVars={setDirectVars} 
              heatingEmissionFunction={heatingEmissionFunction} 
              heatingEnergyFunction={heatingEnergyFunction}
              electricEmissionFunction={electricEmissionFunction}
              electricEnergyFunction={electricEnergyFunction}
              transportationEmissionFunction={transportationEmissionFunction}
              transportationEnergyFunction={transportationEnergyFunction}
              indirectVars={indirectVars}
              setIndirectVars={setIndirectvars}
              dietFunction={dietFunction}
              consumptionFunction={consumptionFunction}
              servicesFunction={servicesFunction}
              totalEmissions={totalEmissions}
            /> 
          } />
          <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  )
}

export default App;
